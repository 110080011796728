import React, { useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import  { Amplify, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsConfig from '../aws-exports';

import {
  getUser, handleLogin, 
  isLocalhost, isTestServer, isProdServer 
} from '../components/Auth.js';

const [
  localRedirectSignIn,
  testRedirectSignIn,
  prodRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  testRedirectSignOut,
  prodRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : 
      isTestServer? testRedirectSignIn : prodRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : 
      isTestServer? testRedirectSignOut : prodRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

export default function NavBar( {stage, setStage, updateUser}) {
  const [user, setUser] = useState(null);
  const handleUser = (user) => {
    setUser(user);
    updateUser(user);
  }
  useEffect(() => {
    handleLogin(getUser, handleUser);
  }, []);

  const menuId = 'primary-search-account-menu';
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Data Diag 
          </Typography>
          <Box sx={{ minWidth: 320, marginLeft: 4}}>
            <FormControl size="small" >
              <InputLabel id="simple-select-label">"Branch"</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={stage}
                label="Branch"
                onChange={(e)=>setStage(e.target.value)}
              >
                <MenuItem value={'prod'}>production data</MenuItem>
                <MenuItem value={'stage'}>stage data</MenuItem>
                <MenuItem value={'dev'}>dev data</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-haspopup="true"
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        {user ? (
          <div> {user.attributes.email}
            <Button color="inherit" onClick={() => Auth.signOut()}>Sign Out</Button>
          </div>
        ) : (
          <Button color="inherit" onClick={() => Auth.federatedSignIn(
            { provider: CognitoHostedUIIdentityProvider.Google })}
          >Sign In with Google Account</Button>
        )}

        </Toolbar>
      </AppBar>
    </Box>
  );
}
