import { Auth, Hub } from 'aws-amplify';

export const getUser = (setUser) => {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => {
        setUser(null);
        console.log('Not signed in');
      });
  }

export const handleLogin = (getUser, setUser) => {
  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn':
      case 'cognitoHostedUI':
        getUser().then(userData => {
          setUser(userData);
        });
        break;
      case 'signOut':
        setUser(null);
        console.log("sign out");
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        console.log('Sign in failure', data);
        break;
    }
  });
  getUser().then(userData => {
    setUser(userData);
  });
};

export const isUserAllowed = (user) => {
  let usergroups = [];
  let isUserAllowed = false;
  if (!user) {
    isUserAllowed = false;
  } else {
    usergroups = user.signInUserSession.idToken.payload["cognito:groups"]
    console.log(usergroups)
  }
  if (usergroups.indexOf("admin") >= 0) {
    isUserAllowed = true;
  }
  return isUserAllowed;
}


export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);


export const isTestServer = Boolean(
  window.location.hostname === "test.datadiag.mgiscloud.com" 
);

export const isProdServer = Boolean(
  window.location.hostname === "datadiag.mgiscloud.com" 
);

