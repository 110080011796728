import React, { useEffect, useState, useMemo } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Papa from 'papaparse'; // A popular CSV parsing library

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import autocolors from 'chartjs-plugin-autocolors';
import { Doughnut} from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, autocolors);


const UserAuditPanel = ({ stage }) => {
  const bucket = 'mgis-user-request';
   
  const [userData, setUserData] = useState([]);
  // const [pieData, setPieData] = useState([]);
  const pieData = useMemo(() => {
    if (userData.length > 0) {
      const aggData = countUniqueValuesPerGroup(
        userData, 'UNIQNAME', 'ADMIN_DEPT_GRP');
      console.log(aggData);
      const pieData = {
        labels: aggData.map(row => row['ADMIN_DEPT_GRP']),
        datasets: [{
          data: aggData.map(row => row.count)
        }],
      };
      // return pieData 
      return aggData 
    } else {
      console.log("TTE")
      return {
        labels: [],
        datasets: [{
          data: []
        }]
      } 
    }
  }, [userData])
  
  const fetchLogData = async () => {
    const url = `https://0wwk4gpfq5.execute-api.us-east-1.amazonaws.com` + 
      `/dev/file?bucket=${bucket}&key=output/result-current.csv`;  
    const res = await fetch(url);
    const text = await res.text();
    const result = Papa.parse(text, { header: true });

    const data = result.data.map((res, index) => {return {...res, id:index }});
    setUserData(data); 
    
  }

  const columns = [
    { hide: true, field: 'ADMIN_DEPTID'},
    { hide: true, field: 'ADMIN_DEPT_DESCR'},
    { hide: true, field: 'ADMIN_DEPT_EFFDT'},
    { hide: true, field: 'ADMIN_DEPT_END_DT'},
    { hide: true, field: 'ADMIN_DEPT_GRP'},
    { hide: true, field: 'ADMIN_DEPT_GRP_DESCR'},
    { hide: true, field: 'ADMIN_DEPT_GRP_VP_AREA'},
    { hide: true, field: 'ADMIN_DEPT_GRP_VP_AREA_DESCR'},
    { hide: true, field: 'APPT_DEPTID'},
    { hide: true, field: 'APPT_DEPT_DESCR'},
    { hide: true, field: 'APPT_DEPT_GRP'},
    { hide: true, field: 'APPT_DEPT_GRP_DESCR'},
    { hide: true, field: 'APPT_DEPT_GRP_VP_AREA'},
    { hide: true, field: 'APPT_DEPT_GRP_VP_AREA_DESCR'},
    { hide: true, field: 'EMPLID'},
    { hide: true, field: 'EMPL_RCD'},
    { hide: false, field: 'EMPL_STATUS'},
    { hide: true, field: 'EMPL_STATUS_DESCR'},
    { hide: true, field: 'IS_ADMIN_DEPT_CURRENT_RECORD'},
    { hide: true, field: 'IS_ADMIN_DEPT_CURRENT_RECORD_ACTIVE'},
    { hide: true, field: 'IS_EMPL_STATUS_ACTIVE'},
    { hide: true, field: 'IS_EMP_RECORD_IN_SCOPE'},
    { hide: true, field: 'IS_JOB_CURRENT_RECORD'},
    { hide: true, field: 'IS_JOB_CURRENT_RECORD_ACTIVE'},
    { hide: true, field: 'IS_RESP_DEPT_CHANGED_AFTER_REQUEST'},
    { hide: true, field: 'IS_USER_CURRENT_EMPLOYEE'},
    { hide: true, field: 'JOBCODE'},
    { hide: false, field: 'JOBCODE_DESCR'},
    { hide: false, field: 'JOB_EFFDT'},
    { hide: true, field: 'JOB_EFFSEQ'},
    { hide: true, field: 'JOB_END_DT'},
    { hide: true, field: 'JOB_FAMILY'},
    { hide: true, field: 'JOB_FAMILY_DESCR'},
    { hide: true, field: 'JOB_INDICATOR'},
    { hide: true, field: 'MOST_RECENT_REQUEST_ADD'},
    { hide: true, field: 'MOST_RECENT_REQUEST_REMOVE'},
    { hide: false, field: 'NAME'},
    { hide: true, field: 'REG_TEMP'},
    { hide: true, field: 'REG_TEMP_DESCRSHORT'},
    { hide: true, field: 'RESP_DEPTID'},
    { hide: false, field: 'RESP_DEPT_DESCR'},
    { hide: true, field: 'RESP_DEPT_GRP'},
    { hide: true, field: 'RESP_DEPT_GRP_DESCR'},
    { hide: true, field: 'RESP_DEPT_GRP_VP_AREA'},
    { hide: true, field: 'RESP_DEPT_GRP_VP_AREA_DESCR'},
    { hide: true, field: 'SUPERVISOR_ID'},
    { hide: true, field: 'SUPERVISOR_NAME'},
    { hide: false, field: 'UNIQNAME'},
    { hide: true, field: 'WAS_USER_EVER_EMPLOYEE'},
  ];

  return (
    <div>
      <h2>User Audit Table</h2>
      <Button onClick={fetchLogData}>load</Button>

      <h3>Full Dataset</h3>
      <div style={{ height: 600, width: '100%' }}> 
        <DataGrid 
          rows={userData} 
          columns={columns} 
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </div>
      <h3>Unique Users by School</h3>
      <div style={{ height: 600, width: '100%' }}> 
        {/* <Doughnut data={pieData}/>; */}
        <DataGrid 
          rows={pieData} 
          columns={[
            {field:'ADMIN_DEPT_GRP', width:300},
            {field:'count'},
          ]} 
          slots={{
            toolbar: GridToolbar,
          }}
          getRowId={(row)=> row.ADMIN_DEPT_GRP}
        />

      </div>
    </div >
  );
}

function countUniqueValuesPerGroup(dataset, uniqueProp, groupProp) {
  const uniqueValuesPerGroup = dataset.reduce((acc, item) => {
    const uniqueValue = item[uniqueProp];
    const group = item[groupProp];
    const groupMap = acc[group] || new Map();
    const count = groupMap.get(uniqueValue) || 0;
    groupMap.set(uniqueValue, count + 1);
    return { ...acc, [group]: groupMap };
  }, {});

  const result = [];

  for (const group in uniqueValuesPerGroup) {
    const uniqueValues = uniqueValuesPerGroup[group];
    result.push({ [groupProp]: group, count: uniqueValues.size });
  }

  return result;
}




export default UserAuditPanel;
