import React, { useState , useMemo} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

const DrawingReportPanel = ({stage }) => {
  const bucket = 'mba-fme-log';
  const date = new Date()
  const [searchDate, setSearchDate] = useState(date.toISOString().split('T')[0]);
  const [logData, setLogData] = useState([]);
  const [source, setSource]  = useState('aec');

  const fetchLogData = async () => {
    const url = `https://0wwk4gpfq5.execute-api.us-east-1.amazonaws.com` + 
      `/dev/file?bucket=${bucket}&key=${stage}/report/report-${searchDate}-${source}.json`;  
    const res = await fetch(url);
    const log = await res.json();
    console.log(log);
    setLogData(log); 
  }

  const handleDateChange = (e) => {
    setSearchDate(e.target.value);
  }
  
  const columns = [
    { field: 'bid' },
    { field: 'bld_flr'},
    { field: 'converted_at'},
    { field: 'dwg'},
    { field: 'fl'},
    { field: 'fl_exclude'},
    { field: 'fme_workspace'},
    { field: 'has_worldfile'},
    { field: 'in_benv'},
    { field: 'in_bset'},
    { field: 'in_dset'},
    { field: 'is_mustlisted'},
    { field: 'is_skiplisted'},
    { field: 'mtime'},
    { field: 'source'},
    { field: 'status'},
    { field: 'update_failed'},
  ];
  return (
    <div>
      <h2>Drawing Reports</h2>
      <p>
      <li> fl_exclude: true if the floor is listed in exclusion list
      </li>
      <li> source: aec  (campus) or uh (michigan medicine)     
      </li>
      <li> mtime: last modified time of the drawing
      </li>
      <li> has_worldfile: true if worldfile exists for the drawing 
      </li>
      <li> is_skiplisted: true if the floor is in the skip list (not converting) 
      </li>
      <li> is_mustlisted: true if the floor is in the must list  
      </li>
      <li> in_dset: true if drawing has worldfile & not skiplisted or mustlisted  
      </li>
      <li> in_benv: true if the building belongs to MGIS's target buildings 
      </li>
      <li> in_bset: intersection of in_dset and in_benv 
      </li>
      <li> status: action decision - add/remove/skip 
      </li>
      </p>
      <Box>
        <input type="date" value={searchDate} onChange={handleDateChange}/>
        <select value={source} onChange={(e)=>setSource(e.target.value)}>
          <option value="aec">aec</option>
          <option value="uh">uh</option>
        </select>
        <Button onClick={fetchLogData}>load</Button>
      </Box>
      <div style={{ height: 800, width: '100%' }}> 
        <DataGrid 
          rows={logData} 
          columns={columns} 
          getRowId={(row)=> row.bld_flr}
        />
      </div>
    </div >
  );
}

export default DrawingReportPanel;
