import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index && ( */}
      { (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DataTabs( {
  RdsPanel, DwprodPanel, 
  FmeLogPanel, DrawingReportPanel, CronLogPanel, RunnerLogPanel,
  UserAuditPanel
}) {

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="RDS Room Data" {...a11yProps(0)} />
          <Tab label="DWPROD Room Data" {...a11yProps(1)} />
          <Tab label="Crontab Log" {...a11yProps(2)} />
          <Tab label="Drawing Report" {...a11yProps(3)} />
          <Tab label="Runner Log" {...a11yProps(4)} />
          <Tab label="FME Log" {...a11yProps(5)} />
          <Tab label="User Audit" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} >
        {RdsPanel} 
      </TabPanel>
      <TabPanel value={value} index={1}>
        {DwprodPanel} 
      </TabPanel>
      <TabPanel value={value} index={2}>
        {CronLogPanel}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {DrawingReportPanel}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {RunnerLogPanel}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {FmeLogPanel}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {UserAuditPanel}
      </TabPanel>

      
   </Box>
  );
}
