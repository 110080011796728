import React, { useEffect, useState, useMemo } from 'react';
import NavBar from './components/NavBar.js';
import DataTabs from './components/DataTabs.js';
import RdsRoomDataPanel from './components/RdsRoomDataPanel.js';
import DwprodRoomDataPanel from './components/DwprodRoomDataPanel.js';
import FmeLogPanel from './components/FmeLogPanel.js';
import DrawingReportPanel from './components/DrawingReportPanel.js';
import CronLogPanel from './components/CronLogPanel.js';
import RunnerLogPanel from './components/RunnerLogPanel.js';
import UserAuditPanel from './components/UserAuditPanel.js';
import {isUserAllowed} from './components/Auth.js';

import './App.css';

function App() {

  const [stage, setStage] = useState('dev')
  const [user, setUser] = useState(null);
  
  return (
    <div className="App">
      <NavBar stage={stage} setStage={setStage} updateUser={setUser}/>
      {isUserAllowed(user) ?
        <DataTabs 
          RdsPanel={<RdsRoomDataPanel stage={stage}/>}
          DwprodPanel={<DwprodRoomDataPanel stage={stage}/>}
          FmeLogPanel={<FmeLogPanel stage={stage}/>}
          DrawingReportPanel={<DrawingReportPanel stage={stage}/>}
          CronLogPanel={<CronLogPanel stage={stage} />}
          RunnerLogPanel={<RunnerLogPanel stage={stage} />}
          UserAuditPanel={<UserAuditPanel stage={stage} />}
        /> :
        <p> you are not allowed. please login</p>
      }
    </div>
  );
}

export default App;
