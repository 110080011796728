import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { CodeBlock, dracula } from "react-code-blocks";

const RunnerLogPanel = ({stage }) => {
  const bucket = 'mba-fme-log';
 
  const date = new Date()
  const [searchDate, setSearchDate] = useState(date.toISOString().split('T')[0]);
  const [source, setSource]  = useState('aec');
  const [logData, setLogData] = useState('');
  
  const fetchLogData = async () => {
    const url = `https://0wwk4gpfq5.execute-api.us-east-1.amazonaws.com` + 
      `/dev/file?bucket=${bucket}&key=${stage}/report/summary-${searchDate}-${source}.log`;  
    const res = await fetch(url);
    const log = await res.text();
    setLogData(log); 
  }
  const handleDateChange = (e) => {
    setSearchDate(e.target.value);
  }

  return (
    <div>
      <h2>FME Runner Execution Log</h2>
      <input type="date" value={searchDate} onChange={handleDateChange}/>
      <select value={source} onChange={(e)=>setSource(e.target.value)}>
          <option value="aec">aec</option>
          <option value="uh">uh</option>
      </select>
      <Button onClick={fetchLogData}>load</Button>
      <code>
        <CodeBlock
          text={logData}
          language={'text'}
          theme={dracula}
        />
      </code>
    </div >
  );
}

export default RunnerLogPanel;
