import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import ResultDisplay from './ResultDisplay'

const DwprodRoomDataPanel = ({ stage }) => {
  
  const [searchValue, setSearchValue] = React.useState('');
  const [rrn, setRrn] = useState('');
  const [roomData, setRoomData] = useState({});
   

  useEffect(()=>{
    fetchRoomData(rrn)
  }, [rrn]);
  
  const fetchRoomData = async (rrn) => {
    const url = `https://f2vhdoazs8.execute-api.us-east-1.amazonaws.com/${stage}/room?rrn=${rrn}`;
    const res= await fetch(url);
    const data = await res.json();
    console.log(data);
    const result = {'svy_tbl': data['results']};
    setRoomData(result);
  }
  const descriptions = [
    {
      'key': 'svy_tbl',
      'desc': '\
      This table from DWPROD contains room attributes from SVY_TBL. \
      If there is an issue in this table there might be an issue in MPathway data.'
    }  
  ]
  return (
    <div>
      <h2>DWPROD Room Data</h2>
      Enter room record number below and click search button
      (e.g. 2182262)
      <Box>
          <TextField
            placeholder="RMRECNBR"
            variant="standard"
            value={searchValue}
            onChange={(e)=>setSearchValue(e.target.value)}
          />
        <Button 
          sx={{ display:'inline'}} 
          variant="contained" 
          onClick={()=>setRrn(searchValue)}>Search
        </Button>
      </Box>
      current rmrecnbr: {rrn}
      <a 
        href={`https://mgis.med.umich.edu/#feature=search&rrn=${rrn}`} 
        target="_blank"> (open in mgis)
      </a>
      {
        descriptions.map((dataInfo)=>{
         return <ResultDisplay 
          key={dataInfo['key']} 
          dataInfo={dataInfo} dataset={roomData[dataInfo['key']]}/>
        })
      }
    </div >
  );
}

export default DwprodRoomDataPanel;
