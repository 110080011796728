import React, { useEffect, useState } from 'react';
import ResultDisplay from './ResultDisplay'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const RdsRoomDataPanel = ({stage}) => {

  const [searchValue, setSearchValue] = React.useState('');
  const [rrn, setRrn] = useState('');
  const [roomData, setRoomData] = useState({});
  //  
  // useEffect(()=>{
  //   fetchRoomData(rrn)
  // },[searchValue]);
  // 
  const fetchRrn = async (searchValue) => {
    const urlBase = `https://xy737wrjje.execute-api.us-east-1.amazonaws.com`;
    const brn = searchValue.split('@')[1];
    const rmnbr = searchValue.split('@')[0];
    const urlSearch =  `/${stage}/room?brn=${brn}&rmnbr=${rmnbr}`;
    const url = urlBase + urlSearch;
    console.log(url);
    const res = await fetch(url);
    const data = await res.json();
    const result = data['results']['search_result'];
    // setRrn(result[0]['rmrecnbr'])
    return result[0]['rmrecnbr']
  } 

  const fetchRoomData = async (rrn) => {
    const urlBase = `https://xy737wrjje.execute-api.us-east-1.amazonaws.com`;
    const urlSearch = `/${stage}/room?rrn=${rrn}`;
    const url = urlBase + urlSearch;
    console.log(url);
    const res = await fetch(url);
    const data = await res.json();
    const result = data['results']['search_result'];
    console.log(result);
    setRoomData(result);
  }

  const handleSearch = async (searchValue) => {
    if (searchValue.includes('@')) {
      searchValue = await fetchRrn(searchValue)   
    }       
    fetchRoomData(searchValue);
    setRrn(searchValue);
  }

  const descriptions = [
    {
      'key': 'room_dwdata',
      'desc': '\
      This table from AWS RDS contains room attributes data copied from dwprod. Check these things: \n \
        1) whether the result exists. \n\
        2)  check fetched_at column  \n\
        3) rmrecnbr and rmnbr is correct \n \
      If there is an issue in this table there might be an issue in copying the table or in the original dwprod table'
    },{
      'key': 'room_raw',
      'desc': 'This table from AWS RDS contains raw geometry converted by Safe FME. \
          If there is an issue in this table, it means there is an issue in FME conversion. '
    },{
      'key': 'room_joined_mv',
      'desc': 'This is a joined view from the two tables above (and several others). If there is an issue in this table \
        it might be an issue in joining process.'
    },{
      'key': 'room_geom',
      'desc': 'This is a geometry converted to wkt format which is human readable format. Use this to examine the geometry.'
    },{
      'key': 'floor_raw',
      'desc': 'This has information when the drawing is drawn and converted.'
    }

  ]
  return (
    <div>
      <h2>RDS Room Data</h2>
      Enter room record number or room number below and click search button
      (e.g. 2182262 or 1461@1005266)
      <Box>
        <TextField
          placeholder="RMRECNBR"
          variant="standard"
          value={searchValue}
          onChange={(e)=>setSearchValue(e.target.value)}
        />
        <Button 
          sx={{ display:'inline'}} 
          variant="contained" 
          onClick={(e)=>handleSearch(searchValue.trim())}>Search
        </Button>
      </Box>
      current rmrecnbr: {rrn}
      <a 
        href={`https://mgis.med.umich.edu/#feature=search&rrn=${rrn}`} 
        target="_blank"> (open in mgis)
      </a>
      {
        descriptions.map((dataInfo)=>{
          return (
          <ResultDisplay 
            key={dataInfo['key']} 
            dataInfo={dataInfo} 
            dataset={roomData[dataInfo['key']]}
          />)
        })
      }
    </div >
  );
}
export default RdsRoomDataPanel;
