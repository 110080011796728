import React, { useEffect, useState } from 'react';
import { CodeBlock, dracula } from "react-code-blocks";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



const FmeLogPanel = ({ stage }) => {
  const bucket = 'mba-fme-log';
  
  const [searchValue, setSearchValue] = React.useState('');
  const [bldFlr, setBldFlr] = useState('');
  const [logData, setLogData] = useState('');
  
  useEffect(()=>{
    if (bldFlr !== '') {
      fetchLogData()}
  }, [bldFlr]);

   const fetchLogData = async () => {
    const url = `https://0wwk4gpfq5.execute-api.us-east-1.amazonaws.com` + 
      `/dev/file?bucket=${bucket}&key=${stage}/log/${bldFlr}.log`
    const res = await fetch(url);
    const log = await res.text();
    setLogData(log); 
  }

  return (
    <div>
      <h2>FME Conversion logs</h2>
      Enter building and floor below and click search button
      (e.g. 1005263_0G)
      <Box>
        <TextField
          placeholder="BLD_FLR"
          variant="standard"
          value={searchValue}
          onChange={(e)=>setSearchValue(e.target.value)}
        />
        <Button 
          sx={{ display:'inline'}} 
          variant="contained" 
          onClick={(e)=>setBldFlr(searchValue)}>Search
        </Button>
      </Box>
      bld_flr: {bldFlr}
      <code>
        <CodeBlock
          text={logData}
          language={'perl'}
          theme={dracula}
        />
      </code>
    </div >
  );
}

export default FmeLogPanel;
