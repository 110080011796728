import { JsonViewer } from '@textea/json-viewer'

const ResultDisplay = ({dataInfo, dataset}) => {
  return (
  <div>
    <h3> {dataInfo['key']}</h3>
    <p>{dataInfo['desc']}</p>
    <JsonViewer value={dataset} theme="dark" rootName="searchResult"/>
  </div>
  )
}

export default ResultDisplay;
